import { css } from "aphrodite";
import { List } from "immutable";
import PropTypes from "prop-types";
import { memo } from "react";

import CreatorGuestItem from "./CreatorGuestItem";

import { GUEST_CODE, GUEST_HOST_CODE } from "utils/roles";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  creatorsContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "0.1rem",
  },

  ellipsisWrapper: {
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline-block",
  },
  bottomRowContainer: {
    display: "grid",
    height: 24,
    gridTemplateColumns: "90px 1fr",
    gridGap: "1rem",
    alignItems: "center",
    maxWidth: "100%",
    marginBottom: "0.5rem",
    [createBreakpoint({ min: 1115 })]: {
      maxWidth: 370,
      display: "grid",
    },
  },
  bottomRowLabel: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    display: "flex",
    gap: "0.5rem",
  },
};

const GUEST_ROLES_CODES = [GUEST_CODE, GUEST_HOST_CODE];

const CreatorGuestItems = (props) => {
  const { episode, context } = props;
  const { styles } = useStyles(baseStyles, props);

  const guests = episode?.get("creator_with_roles")?.filter((creator) => {
    return !!creator
      ?.get("roles")
      ?.find((role) => GUEST_ROLES_CODES.includes(role?.get("role")));
  });

  if (guests && (guests?.size > 0 || guests?.length > 0)) {
    return (
      <div className={css(styles.bottomRowContainer)}>
        <h3 className={css(styles.bottomRowLabel)}>Guests</h3>
        <div className={css(styles.creatorsContainer)}>
          <span className={css(styles.ellipsisWrapper)}>
            {guests?.slice(0, 4).map((guest, index) => {
              if (!guest) {
                return null;
              }
              return (
                <CreatorGuestItem
                  key={index}
                  index={index}
                  guest={guest}
                  entity={episode}
                  size={guests?.slice(0, 4).size}
                  episodeId={episode.get("id")}
                  context={context}
                />
              );
            })}
          </span>
        </div>
      </div>
    );
  }

  return null;
};

CreatorGuestItems.propTypes = {
  ids: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
};

export default memo(CreatorGuestItems);
