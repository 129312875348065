import useLoggedInUser from "hooks/useLoggedInUser";
import useUserList from "hooks/useUserList";

export default function useIsListOwner(listId) {
  const loggedInUser = useLoggedInUser();

  const list = useUserList(listId);
  const listOwnerId = list?.getIn(["owners", 0]);

  return listOwnerId === loggedInUser?.get("id");
}
