import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo } from "react";
import { Link } from "react-router-dom";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import sendGAEvent from "utils/sendGAEvent";
import { getCreatorUrl } from "utils/url/creatorUrls";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  text: {
    ...gStyles.fontRegular,
    fontSize: ".75rem",
    lineHeight: "16px",
    display: "inline",
    color: colours.darkGrey,
    margin: 0,
    whiteSpace: "nowrap",
    marginRight: "0.3rem",
    ":hover": {
      color: colours.primary,
    },
    ":last-child": {
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
};

const CreatorGuestItem = (props) => {
  const { index, size, guest, episodeId, context } = props;
  const { styles } = useStyles(baseStyles, props);

  const creatorLink = getCreatorUrl(guest);

  const onGuestClick = (guest) => {
    sendGAEvent({
      action: "GuestItemClick",
      creator_name: guest?.get("name"),
      creator_id: guest?.get("id"),
      context,
      ...(episodeId && { episode_id: episodeId }),
    });
  };

  return (
    <BasicTooltip renderTooltip={() => guest?.get("name")}>
      {(tooltipProps) => (
        <Link
          {...tooltipProps}
          key={guest?.get("id")}
          to={creatorLink}
          className={css(styles.text)}
          onClick={() => onGuestClick(guest)}
        >
          {`${guest?.get("name")}${index === size - 1 ? "" : ","}`}
        </Link>
      )}
    </BasicTooltip>
  );
};

CreatorGuestItem.propTypes = {
  index: PropTypes.number,
  guest: PropTypes.instanceOf(Map),
  size: PropTypes.number,
};

export default memo(CreatorGuestItem);
