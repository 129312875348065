import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const loadSpecificList = createRoutine("LOAD_SPECIFIC_LIST");
export const updateListOrder = createRoutine("UPDATE_LIST_ORDER");
export const addItemToList = createRoutine("ADD_ITEM_TO_LIST");
export const addItemToListPromise = promisifyRoutine(addItemToList);
export const addItemsToList = createRoutine("ADD_ITEMS_TO_LIST");
export const addItemsToListPromise = promisifyRoutine(addItemsToList);
export const removeItemFromList = createRoutine("REMOVE_ITEM_FROM_LIST");
export const removeItemFromListPromise = promisifyRoutine(removeItemFromList);
export const setListPrivacy = createRoutine("SET_LIST_PRIVACY");
export const setListPrivacyPromise = promisifyRoutine(setListPrivacy);
export const createNewList = createRoutine("CREATE_NEW_LIST");
export const createNewListPromise = promisifyRoutine(createNewList);
export const saveNoteForItem = createRoutine("SAVE_NOTE_FOR_USERLIST_ITEM");
export const deleteNoteForItem = createRoutine("DELETE_NOTE_FOR_USERLIST_ITEM");
export const saveListDetails = createRoutine("SAVE_USERLIST_DETAILS");
export const saveListDetailsPromise = promisifyRoutine(saveListDetails);
export const loadProfileUserLists = createRoutine("LOAD_PROFILE_USERLISTS");
export const getListSummaries = createRoutine("GET_USERLIST_SUMMARIES");
export const getListSummariesPromise = promisifyRoutine(getListSummaries);
export const deleteList = createRoutine("DELETE_USERLIST");
export const deleteListPromise = promisifyRoutine(deleteList);
export const updateSectionHeading = createRoutine(
  "UPDATE_SECTION_HEADING_USERLIST"
);
export const updateSectionHeadingPromise =
  promisifyRoutine(updateSectionHeading);
export const regenerateLinkOnlyID = createRoutine("REGENERATE_LINK_ONLY_ID");
export const regenerateLinkOnlyIDPromise =
  promisifyRoutine(regenerateLinkOnlyID);
export const loadContactCounts = createRoutine("LOAD_CONTACT_COUNTS");
export const loadContactCountsPromise = promisifyRoutine(loadContactCounts);

export const loadUserlistInsights = createRoutine("USERLIST_INSIGHTS");
export const loadUserlistInsightsPromise =
  promisifyRoutine(loadUserlistInsights);
