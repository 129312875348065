import { Link } from "react-router-dom";

import HighLightText from "components/Highlight/HighLightText";

import PodcastProfileHoverPopover from "../../../Profiles/PodcastProfileHoverPopover";
import EntityImage from "../../EntityImage";
import PodcastCardStats from "../PodcastCardStats";

import getEpisodeUrl from "utils/entity/getEpisodeUrl";
import getPodcastAuthorityLevel from "utils/entity/getPodcastAuthorityLevel";
import getPodcastHasGuests from "utils/entity/getPodcastHasGuests";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  container: {
    display: "flex",
    gap: "1rem",
    flexDirection: "column",
    marginBottom: 8,
    width: "100%",
  },
  innerContainer: {
    display: "flex",
    gap: 5,
    alignItems: "center",
    color: "var(--color-neutral-d5)",

    [createBreakpoint({ min: 1115, max: 1175 })]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  episodeTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
  },
  label: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    color: "var(--color-neutral-d5)",

    maxWidth: 72,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: "0.5rem",
    [ScreenSizes.lgAndAbove]: {
      maxWidth: 165,
    },
  },
  image: {
    width: 16,
    height: 16,
  },
  flex: {
    display: "flex",
    gap: 5,
  },
};

const imagestyles = {
  entityImage: {
    background: null,
  },
};

export default function EpisodePodcastInfoRow(props) {
  const { styles, css } = useStyles(baseStyles, props);

  const { episode, podcast, principalSearchTerm, secondarySearchTerm } = props;

  const imageUrl = podcast?.get("image_url");

  const episodeTitle = episode?.get("title");

  const episodeCardIcons = (podcast) =>
    [
      getPodcastAuthorityLevel(podcast, 0) && {
        type: "authority",
        value: getPodcastAuthorityLevel(podcast, 0),
        to: getPodcastUrl(podcast, "/insights"),
        proOnly: true,
      },
      getPodcastHasGuests(podcast) !== undefined && {
        type: "hasGuests",
        value: getPodcastHasGuests(podcast),
        to: getPodcastUrl(podcast, "/insights"),
        proOnly: true,
      },
    ].filter((iconType) => iconType);

  const handleTitleClick = () => {
    sendGAEvent({
      action: "entityLinkClicked",
      entity_type: "episode",
      entity_id: episode?.get("id"),
      entity_name: episode?.get("title"),
    });
  };

  return (
    <div className={css(styles.container)}>
      {podcast && (
        <div className={css(styles.innerContainer)}>
          <div className={css(styles.flex)}>
            <EntityImage
              entity={podcast}
              entity_type="podcast"
              image_url={imageUrl}
              size={16}
              borderRadius={3}
              styles={imagestyles}
              className={css(styles.image)}
            />
            <PodcastProfileHoverPopover entity_id={podcast?.get("id")}>
              <Link
                className={css(styles.link, styles.label)}
                onClick={handleTitleClick}
                to={getPodcastUrl(podcast, "/insights")}
              >
                {podcast?.get("title")}
              </Link>
            </PodcastProfileHoverPopover>
          </div>

          <PodcastCardStats
            inlineContent={null}
            hideIsVerified
            hideEmptyRating
            podcast={podcast}
            passedIcons={episodeCardIcons}
          />
        </div>
      )}
      {episode && (
        <Link to={getEpisodeUrl(episode)} className={css(styles.episodeTitle)}>
          <HighLightText
            textToHighlight={episodeTitle}
            principalSearchTerm={principalSearchTerm}
            secondarySearchTerm={secondarySearchTerm}
          />
        </Link>
      )}
    </div>
  );
}
