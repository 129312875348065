import { faLock } from "@fortawesome/pro-regular-svg-icons/faLock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  bottomRowContainer: {
    display: "none",

    gridTemplateColumns: "90px 1fr",
    gridGap: "1rem",
    alignItems: "center",
    maxWidth: "100%",

    [createBreakpoint({ min: 1115 })]: {
      maxWidth: 370,
      display: "grid",
    },
  },
  bottomRowLabel: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    display: "flex",
    gap: "0.5rem",
  },
  lock: {
    ...gStyles.fontRegular,
    fontSize: "0.75rem",
    color: colours.secondary,
    textDecoration: "underline",
    textDecorationStyle: "dashed",
    outline: "none",
    border: "none",
    display: "flex",
    gap: 4,
    alignItems: "center",
    textWrap: "wrap",
    padding: 0,
    textAlign: "start",

    ":hover": {
      cursor: "pointer",
    },
  },
};

export default function EpisodeCardTranscriptCTA(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { episodeId, episodeTitle } = props;

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const onDemoRequestSubmitted = useCallback(() => {
    sendGAEvent({
      action: "submittedProLeadFormModal",
      page: "Episodes Search",
      context: "episodesCardTranscript-unlockpro",
      entity: "episode",
      entity_id: episodeId,
      entity_name: episodeTitle,
    });
  }, [episodeId, episodeTitle]);

  const unlockProClick = useCallback(
    (e) => {
      sendGAEvent({
        action: "openedProLeadFormModal",
        context: "episodesCardTranscript-unlockpro",
        entity: "episode",
        entity_id: episodeId,
        entity_name: episodeTitle,
      });

      showModal("proLeadForm", {
        onDemoRequestSubmitted,
      });

      return false;
    },
    [episodeId, episodeTitle, showModal, onDemoRequestSubmitted]
  );

  if (!episodeId) {
    return null;
  }

  return (
    <div className={css(styles.bottomRowContainer)}>
      <h3 className={css(styles.bottomRowLabel)}>Transcript</h3>
      <p onClick={unlockProClick} className={css(styles.lock)}>
        <span className={css(styles.icon)}>
          <FontAwesomeIcon icon={faLock} data-testid="lock" dataid="lock" />
        </span>
        Unlock transcript search
      </p>
    </div>
  );
}
