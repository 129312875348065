import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List } from "immutable";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import ShowMoreContainer from "components/Common/table/commons/ShowMoreContainer";
import HighLightText from "components/Highlight/HighLightText";

import getEpisodeUrl from "utils/entity/getEpisodeUrl";
import sendGAEvent from "utils/sendGAEvent";

import useEpisodeData from "hooks/useEpisodeData";
import useEpisodeTranscriptToggle from "hooks/useEpisodeTranscriptToggle";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  transcriptContainer: {
    padding: "0 0.5rem",
    borderLeft: `4px solid ${colours.borderGrey}`,
    marginBottom: "1rem",
    borderTopRightRadius: "0.25rem",
    borderBottomRightRadius: "0.25rem",
    ...gStyles.fontRegular,
    fontSize: 14,

    ":hover": {
      cursor: "pointer",
    },
  },
  title: {
    ...gStyles.avalonBold,
    fontSize: "0.75rem",
    margin: 0,
    padding: 0,
  },
  disclaimer: {
    ...gStyles.fontRegularItalic,
    fontSize: 12,
    color: colours.placeHolder,
  },
  titleContainer: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
  },
  icon: {
    fontSize: 10,
  },
  noTranscript: {
    color: colours.placeHolder,
  },
  bottomRowContainer: {
    display: "grid",
    gridTemplateColumns: "90px 1fr",
    gridGap: "1rem",
    alignItems: "center",
    maxWidth: "100%",
  },
};

const prefixTranscript = (transcripts) => {
  return transcripts?.map((transcript) => {
    return `...${transcript}`;
  });
};

const removeDuplicates = (combinedTranscript) => {
  return combinedTranscript?.toSet()?.toList();
};

export const getEpisodeTranscript = (episode) => {
  const transcripts = episode?.getIn(["highlight", "transcript.block_body"]);
  const transcriptCount = episode?.getIn(["transcript_counts", "total"]);
  const exactTranscripts =
    episode?.getIn(["highlight", "transcript.block_body.exact"]) || List();
  const formattedExactTranscripts = prefixTranscript(exactTranscripts);
  const formattedTranscripts = prefixTranscript(transcripts, true);

  let combinedTranscript = formattedTranscripts;
  if (exactTranscripts?.size > 0) {
    const mergedTranscripts =
      formattedExactTranscripts?.merge(formattedTranscripts);
    combinedTranscript = removeDuplicates(mergedTranscripts);
  }

  const formattedTranscript = `${combinedTranscript?.join("")}...`;
  const noMatch =
    transcriptCount > 0 && !transcripts && exactTranscripts?.size === 0;
  const noTranscriptsFound = !transcripts && exactTranscripts?.size === 0;

  return {
    formattedTranscript,
    noMatch,
    noTranscriptsFound,
  };
};

export default function EpisodeCardTranscript(props) {
  const { episodeId, principalSearchTerm, secondarySearchTerm } = props;

  const { styles, css } = useStyles(baseStyles, props);

  const episode = useEpisodeData(episodeId);

  const showingTranscript = useEpisodeTranscriptToggle();
  const episodeUrl = getEpisodeUrl(episode);

  const { formattedTranscript, noMatch, noTranscriptsFound } =
    getEpisodeTranscript(episode);

  const analyticalProps = useMemo(
    () => ({
      action: "transcriptResultClicked",
      context: "episodeCardTranscript",
      episodeId: episode?.get("id"),
      episodeUrl: `${episodeUrl}/transcript`,
      entityType: "episode",
    }),
    [episode, episodeUrl]
  );
  const handleLinkClick = useCallback(() => {
    sendGAEvent({
      action: "detailsLinkClicked",
      ...analyticalProps,
    });
  }, [analyticalProps]);

  const eventHandler = useCallback(
    ({ result }) => {
      sendGAEvent({
        result,
        ...analyticalProps,
      });
    },
    [analyticalProps]
  );

  if (!showingTranscript) {
    return null;
  }

  if (noMatch) {
    return (
      <div className={css(styles.bottomRowContainer)}>
        <h4 className={css(styles.title, styles.noTranscript)}>Transcript</h4>
        <Link
          to={`${episodeUrl}/transcript`}
          className={css(styles.disclaimerContainer)}
          onClick={() => eventHandler({ result: "No Match Found" })}
          data-testid="link"
        >
          <p className={css(styles.disclaimer)}>No Match</p>
        </Link>
      </div>
    );
  }

  if (noTranscriptsFound) {
    return (
      <div className={css(styles.bottomRowContainer)}>
        <h4 className={css(styles.title, styles.noTranscript)}>Transcript</h4>
        <p className={css(styles.disclaimer)}>
          This episode does not have a transcript
        </p>
      </div>
    );
  }

  const link = `${episodeUrl}/transcript`;

  const visibleContentNode = formattedTranscript && (
    <HighLightText
      textToHighlight={formattedTranscript?.substr(0, 250)}
      principalSearchTerm={principalSearchTerm}
      secondarySearchTerm={secondarySearchTerm}
    />
  );

  const hiddenText = formattedTranscript && formattedTranscript?.substr(250);
  const hiddenContent = hiddenText && (
    <Link to={link} onClick={handleLinkClick}>
      <HighLightText
        textToHighlight={hiddenText}
        principalSearchTerm={principalSearchTerm}
        secondarySearchTerm={secondarySearchTerm}
      />
    </Link>
  );

  return (
    <div className={css(styles.container)}>
      <Link
        to={`${episodeUrl}/transcript`}
        className={css(styles.titleContainer)}
        onClick={() => eventHandler({ result: "Match Found" })}
        data-testid="link"
      >
        <h4 className={css(styles.title)}>Transcript</h4>
        <FontAwesomeIcon icon={faChevronRight} className={css(styles.icon)} />
      </Link>
      <div className={css(styles.transcriptContainer)}>
        <ShowMoreContainer
          entityType="episode"
          entity={episode}
          visibleContent={
            <Link to={link} onClick={handleLinkClick}>
              <span>
                "{visibleContentNode}
                {hiddenText === "" && '"'}
              </span>
            </Link>
          }
          hiddenContent={
            <Link to={link} onClick={handleLinkClick}>
              <span>{hiddenContent}"</span>
            </Link>
          }
        />
      </div>
    </div>
  );
}
