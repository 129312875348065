import { format } from "date-fns/format";
import PropTypes from "prop-types";
import { memo } from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  time: {
    ...gStyles.avalonBold,
    fontSize: 12,
    textAlign: "center",
    color: colours.header,
  },
};

const AiredInfoIcon = (props) => {
  const { value } = props;
  const { styles, css } = useStyles(baseStyles, props);

  const formattedDate = format(new Date(value), "MMM do, yyyy");

  return (
    <BasicTooltip renderTooltip={() => formattedDate}>
      {(tooltipProps) => (
        <div className={css(styles.time)} {...tooltipProps}>
          {formattedDate}
        </div>
      )}
    </BasicTooltip>
  );
};

AiredInfoIcon.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

AiredInfoIcon.defaultProps = {
  value: null,
};

export default memo(AiredInfoIcon);
