import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";

import StandardButton from "components/Buttons/StandardButton";
import AddToListEpisode from "components/Common/table/commons/AddToListEpisode";
import RemoveFromListButton from "components/Common/table/commons/RemoveFromListButton";
import ShowMoreContainerWithHighlight from "components/Common/table/commons/ShowMoreContainerWithHighlight";

import CreatorGuestItems from "../../Items/CreatorGuestItems";
import AiredInfoIcon from "../../Items/InfoIcons/AiredInfoIcon";
import CardActions from "../CardActions";
import EllipsisAction from "../CardActions/EllipsisAction";
import EntityCardImage from "../EntityCardImage";
import PodcastCardCategories from "../PodcastCardCategories";
import PodcastDesktopCard from "../PodcastCards/PodcastDesktopCard";
import EpisodeCardTranscript from "./EpisodeCardTranscript";
import EpisodeCardTranscriptCTA from "./EpisodeCardTranscriptCTA";
import EpisodeListenButton from "./EpisodeListenButton";
import EpisodePodcastInfoRow from "./EpisodePodcastInfoRow";

import { selectSpecificPodcast } from "selectors/podcast";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";
import sendGAEvent from "utils/sendGAEvent";

import useEpisodeTranscriptToggle from "hooks/useEpisodeTranscriptToggle";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";
import useWindowSize from "hooks/useWindowSize";

import cardStyles from "styles/CardStyles";
import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  episodeDesktopCard: {
    ...cardStyles.mobileCard,
    minHeight: "13rem",
    padding: "1.5rem 1rem",
    border: `1px solid var(--color-neutral-d85)`,
    borderRadius: "0.75rem",
    [ScreenSizes.mdAndAbove]: {
      ...cardStyles.desktopCard,
      padding: "1.5rem",
      maxWidth: "54rem",
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: 8,
    },
  },
  podcastCardShowing: {
    paddingBottom: "1rem",
  },
  episodeDesktopCardNoPanel: {
    ...cardStyles.cardNoPanel,
  },

  topRow: {
    ...cardStyles.topRow,
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    gridGap: "1rem",
    borderBottom: "none",
    marginBottom: 0,
    paddingBotttom: "0.5rem",
    [ScreenSizes.lgAndAbove]: {
      borderBottom: "none",
      marginBottom: 0,
      paddingBotttom: "0.5rem",
    },
  },
  imageRow: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gridGap: "1rem",
    alignItems: "center",
  },
  descriptionHeading: {
    ...gStyles.avalonBold,
    fontSize: "0.75rem",
    margin: 0,
    marginBottom: "0.5rem",
    padding: 0,
  },
  topActions: {
    display: "none",
    maxWidth: 200,
    [createBreakpoint({ min: 1115 })]: {
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
      height: "2rem",
      width: "100%",
      justifyContent: "flex-end",
    },
  },
  topRowWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexItems: {
    display: "flex",
    gap: "1rem",
  },
  columnDirection: {
    flexDirection: "column",
  },
  center: {
    alignItems: "center",
  },
  bottomActions: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    height: "max-content",
    width: "100%",
    marginTop: "0.5rem",
    paddingTop: "1.5rem",
    borderTop: `1px solid ${colours.borderGrey}`,
    justifyContent: "space-between",
    [createBreakpoint({ min: 1115 })]: {
      display: "none",
    },
  },
  episodeInfo: {
    ...cardStyles.desktopCardEntityInfo,
    paddingTop: 0,
    paddingLeft: 0,
  },
  description: {
    ...cardStyles.desktopCardDescription,
  },
  bottomRow: {
    ...cardStyles.bottomRow,
    flexDirection: "column",
    alignItems: "flex-start",
    [ScreenSizes.xxlAndAbove]: {
      alignItems: "center",
      flexDirection: "row",
      gap: 0,
    },
  },
  actions: {
    ...cardStyles.cardActions,
    marginRight: "auto",
    marginTop: "1rem",
    [ScreenSizes.xxlAndAbove]: {
      marginRight: 0,
      marginTop: 0,
    },
  },
};

const insidePanelStyles = {
  episodeDesktopCard: {
    ...cardStyles.noRaisedCard,
    ...cardStyles.flatCard,
  },
  bottomRow: {
    ...cardStyles.bottomRowInsidePanel,
  },
};

export const passedEllipsisMenuItems = [
  "view",
  "list",
  "bookmark",
  "mark",
  "rate",
  "share",
  "similar",
  "credits",
  "follow",
  "transcript",
];

const playButtonStyles = {
  button: {
    maxHeight: 32,
    height: "2rem",
    width: "2rem",
    borderRadius: 25,
  },
};

const buttonIconSize = {
  buttonIcon: {
    fontSize: 12,
  },
};

const customButtonStyles = {
  button: {
    ...gStyles.avalonBold,
    width: "fit-content",
    border: `1px solid ${colours.borderGrey}`,
    textAlign: "center",
    height: "unset",
    padding: "0.25rem 0.5rem",
    gap: "0.5rem",
    margin: 0,
    whiteSpace: "nowrap",
    background: colours.white,
    ":hover": {
      border: `1px solid ${colours.borderGrey}`,
      background: colours.white,
    },
    ":focus": {
      border: `1px solid ${colours.borderGrey}`,
      background: colours.white,
    },
  },
};

const activeButtonStyles = {
  button: {
    ...gStyles.avalonBold,
    width: "fit-content",
    border: `1px solid ${colours.borderGrey}`,
    textAlign: "center",
    height: "unset",
    padding: "0.25rem 0.5rem",
    gap: "0.5rem",
    margin: 0,
    whiteSpace: "nowrap",
    color: colours.primary,
    background: colours.white,

    ":hover": {
      border: `1px solid ${colours.borderGrey}`,
      background: colours.white,
      color: colours.primary,
    },
    ":focus": {
      border: `1px solid ${colours.borderGrey}`,
      background: colours.white,
      color: colours.primary,
    },
  },
};

const ellipsisActionStyles = {
  onList: {
    background: "var(--color-primary-d10)",
  },
  addToListIcon: {
    padding: "0 0.6rem",
    borderRadius: 25,
    width: "max-content",
    height: "2rem",

    [ScreenSizes.mdAndAbove]: {
      whiteSpace: "nowrap",
    },
  },
  full: {
    padding: "0 0.6rem",
  },
};

const customListenButtonStyles = {
  button: {
    borderRadius: 25,
    border: `1px solid ${colours.borderGrey}`,
    marginRight: 0,
  },
  actioned: {
    background: "var(--color-primary-d10)",
  },
  buttonContainer: {
    marginRight: 0,
  },
};

const cardButtonContainer = {
  buttonContainer: {
    marginRight: 0,
  },
};

const ellipsisStyles = {
  actionButtonsContainer: {
    borderRadius: "25px",
  },
  ellipsisButtonInner: {
    height: "2rem",
    width: "2rem",
    borderRadius: "25px",
    border: `1px solid ${colours.borderGrey}`,
  },
  buttonContainer: {
    marginRight: 0,
    marginLeft: "auto",

    [createBreakpoint({ min: 1115 })]: {
      marginLeft: 0,
    },
  },
};

const PodcastCardStyles = {
  podcastDesktopCard: {
    paddingBottom: "0.5rem",
    boxShadow: "none",
    marginTop: "1.5rem",
    [ScreenSizes.mdAndAbove]: {
      boxShadow: "none",
    },
  },
};

const DESCRIPTION_STYLES = {
  details: {
    marginTop: 0,
    marginBottom: "0.47rem",
  },
};

const analyticsProps = {
  displayType: "CardView",
  card_action_type: "ListActionInsideCardView",
  context: "EpisodeCard",
  entityType: "episode",
};

const getPodcast = (state, episode) => {
  const pulledPodcast = selectSpecificPodcast(
    state,
    episode && episode.get("podcast_id")
  );

  if (!pulledPodcast && episode && typeof episode.get("podcast") === "object") {
    return episode.get("podcast");
  }

  return pulledPodcast;
};

const addToListStyles = {
  ellipsisButtonInner: {
    width: "max-content",
    height: "max-content",
    padding: 0,
    gap: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: 25,
    border: "none",
    fontSize: "0.813rem",
    color: colours.black,
  },
};

const addToListActiveStyles = {
  ellipsisButtonInner: {
    width: "max-content",
    height: "max-content",
    padding: 0,
    gap: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: 25,
    border: "none",
    fontSize: "0.813rem",
    color: colours.primary,
    backgroundColor: "transparent",

    ":focus": {
      backgroundColor: "transparent",
    },
  },
};

const EpisodeDesktopCard = (props) => {
  const {
    episode,
    insidePanel,
    noPanel,
    renderEntityImageOverlay,
    showRemoveItem,
    listId,
    showTranscriptToggle,
    listKey,
    principalSearchTerm,
    secondarySearchTerm,
  } = props;

  const { styles } = useStyles(
    [baseStyles, insidePanel && insidePanelStyles],
    props
  );

  const windowSize = useWindowSize();
  const hasPro = useUserHasPro();
  const showingTranscript = useEpisodeTranscriptToggle();

  const canShowTranscript = useMemo(
    () => hasPro && showTranscriptToggle && showingTranscript,
    [hasPro, showTranscriptToggle, showingTranscript]
  );

  const mobile = windowSize.isWindowSizeOrLess("medium");
  const tiny = windowSize.isWindowSizeOrLess("tiny");

  const podcast = useReduxState(
    (state) => getPodcast(state, episode),
    [episode]
  );

  const episodeWithPodcast = useMemo(
    () => episode && episode.set("podcast", podcast),
    [episode, podcast]
  );

  const episodeUrl = getEpisodeUrl(episodeWithPodcast);

  const isListened = episode?.getIn(["user_data", "listened"]);
  const isRated = episode?.getIn(["user_data", "rating", "rating"]);
  const isBookmarked = episode?.getIn(["user_data", "bookmarked"]);

  const actions = useMemo(() => {
    let episodeActions = [];

    if (isListened) {
      episodeActions.push({ type: "listen" });
    }
    if (isRated) {
      episodeActions.push({ type: "rate" });
    }
    if (isBookmarked) {
      episodeActions.push({ type: "bookmark" });
    }

    return episodeActions;
  }, [isBookmarked, isListened, isRated]);

  const [showPodcastDetail, setOpenPodcastDetail] = useState(false);

  const handleShowPodcastDetailClick = (action) => {
    sendGAEvent({
      action: "showPodcastDetailClick",
      context: `${action}PodcastDetail`,
      entity_name: episode?.get("title"),
      entity_id: episode?.get("id"),
      entity_type: "episode",
    });
    setOpenPodcastDetail((prevState) => !prevState);
  };

  const topRow = (
    <div className={css(styles.topRow)}>
      <div className={css(styles.imageRow)}>
        <EntityCardImage
          entity={episodeWithPodcast}
          entityType="episode"
          entityUrl={episodeUrl}
          renderEntityImageOverlay={renderEntityImageOverlay}
        />
        <div className={css(styles.episodeInfo)}>
          <div className={css(styles.topRowWrapper)}>
            <EpisodePodcastInfoRow
              podcast={podcast}
              episode={episode}
              principalSearchTerm={principalSearchTerm}
              secondarySearchTerm={secondarySearchTerm}
            />
            <div className={css(styles.topActions)}>
              {actions.length > 0 && (
                <CardActions
                  actions={actions}
                  entity={episode}
                  entity_type="episode"
                  podcast={podcast}
                  customButtonStyles={customListenButtonStyles}
                  styles={cardButtonContainer}
                />
              )}
              <AddToListEpisode
                episodeId={episode?.get("id")}
                withLabel
                analyticsProps={analyticsProps}
                styles={ellipsisActionStyles}
                passedEllipsisButtonStyles={addToListStyles}
                passedEllipsisActiveButtonStyles={addToListActiveStyles}
              />
              <EpisodeListenButton
                episode={episode}
                entity_type="episode"
                hideTextLabel
                passedButtonStyles={playButtonStyles}
                styles={buttonIconSize}
              />
              {showRemoveItem && (
                <RemoveFromListButton
                  listId={listId}
                  entityType="episode"
                  entityId={episode?.get("id")}
                />
              )}
              <EllipsisAction
                entity={episode}
                entity_type={"episode"}
                id="ellipsis"
                podcast={podcast}
                showShare={true}
                styles={ellipsisStyles}
                action={{ showEllipsisItems: passedEllipsisMenuItems }}
                closeOnOutsideclick
              />
            </div>
          </div>
          <div className={css(styles.flexItems, styles.center)}>
            <EpisodeListenButton episode={episode} entity_type="episode" />
            <AiredInfoIcon value={episode?.get("air_date")} />
          </div>
        </div>
      </div>
      <div>
        {canShowTranscript && episode.get("description") && (
          <h4 className={css(styles.descriptionHeading)}>
            Episode description
          </h4>
        )}
        <ShowMoreContainerWithHighlight
          styles={DESCRIPTION_STYLES}
          description={episode.get("description")}
          entity={episode}
          entityType="Episode"
          principalSearchTerm={principalSearchTerm}
          secondarySearchTerm={secondarySearchTerm}
        />
      </div>
      {canShowTranscript && (
        <EpisodeCardTranscript
          listKey={listKey}
          episodeId={episode.get("id")}
          principalSearchTerm={principalSearchTerm}
          secondarySearchTerm={secondarySearchTerm}
        />
      )}
      {!hasPro && showTranscriptToggle && (
        <EpisodeCardTranscriptCTA
          episodeId={episode?.get("id")}
          episodeTitle={episode?.get("title")}
        />
      )}
    </div>
  );

  const bottomRow = (
    <div className={css(styles.bottomRow)}>
      <div className={css(styles.flexItems, styles.columnDirection)}>
        <PodcastCardCategories
          episodeId={episode?.get("id")}
          podcastId={podcast?.get("id")}
          context={"EpisodeCardCategoryPills"}
        />
        <CreatorGuestItems
          episode={episode}
          context={"EpisodeCardGuestItemClick"}
        />
      </div>
      <div className={css(styles.bottomActions)}>
        <AddToListEpisode
          episodeId={episode?.get("id")}
          withLabel={!tiny}
          analyticsProps={analyticsProps}
          styles={ellipsisActionStyles}
          passedEllipsisButtonStyles={addToListStyles}
          passedEllipsisActiveButtonStyles={addToListActiveStyles}
        />
        <span className={css(styles.flexItems)}>
          {actions.length > 0 && (
            <CardActions
              actions={actions}
              entity={episode}
              entity_type="episode"
              podcast={podcast}
              customButtonStyles={customListenButtonStyles}
              styles={cardButtonContainer}
            />
          )}
          <EpisodeListenButton
            episode={episode}
            entity_type="episode"
            hideTextLabel
            passedButtonStyles={playButtonStyles}
            styles={buttonIconSize}
          />
          <EllipsisAction
            entity={episode}
            entity_type={"episode"}
            id="ellipsis"
            podcast={podcast}
            showShare={true}
            styles={ellipsisStyles}
            action={{ showEllipsisItems: passedEllipsisMenuItems }}
            closeOnOutsideclick
          />
        </span>
      </div>
      {!mobile && (
        <div className={css(styles.actions)}>
          <StandardButton
            label={
              <div className={css(styles.flexItems, styles.center)}>
                <span>
                  {showPodcastDetail ? "Hide" : "Show"} Podcast Details
                </span>
                <FontAwesomeIcon
                  icon={showPodcastDetail ? faAngleUp : faAngleDown}
                />
              </div>
            }
            overwriteStyles={
              showPodcastDetail ? activeButtonStyles : customButtonStyles
            }
            variation={"transparent"}
            flat
            onClick={() =>
              handleShowPodcastDetailClick(showPodcastDetail ? "Hide" : "Show")
            }
          />
        </div>
      )}
    </div>
  );

  return (
    <div
      className={css(
        styles.episodeDesktopCard,
        noPanel && styles.episodeDesktopCardNoPanel,
        styles.entityCard,
        showPodcastDetail && styles.podcastCardShowing
      )}
    >
      {topRow}
      {bottomRow}
      {showPodcastDetail && (
        <PodcastDesktopCard styles={PodcastCardStyles} podcast={podcast} />
      )}
    </div>
  );
};

EpisodeDesktopCard.propTypes = {
  episode: PropTypes.instanceOf(Map),
  insidePanel: PropTypes.bool,
  noPanel: PropTypes.bool,
  renderEntityImageOverlay: PropTypes.func,
};

EpisodeDesktopCard.defaultProps = {
  episode: null,
  insidePanel: false,
  noPanel: false,
  renderEntityImageOverlay: null,
};

export default EpisodeDesktopCard;
