import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import PlayButton from "components/Buttons/PlayButtonAsync";
import StandardButton from "components/Buttons/StandardButton";

import { secondsToHoursMinSecs } from "utils/formatTime";
import mergeDeep from "utils/objects/mergeDeep";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  buttonIcon: {
    fontSize: ".5rem",
    display: "flex",
  },
};

const customButtonStyles = {
  button: {
    ...gStyles.avalonBold,
    width: "fit-content",
    border: `1px solid ${colours.borderGrey}`,
    fontSize: 12,
    textAlign: "center",
    height: "unset",
    padding: "0.1rem 0.5rem",
    gap: "0.5rem",
    margin: 0,
    boxShadow: "none",
    [ScreenSizes.mdAndAbove]: {
      fontSize: 12,
    },
    ":hover": {
      border: `1px solid ${colours.borderGrey}`,
      boxShadow: "none",
    },
    ":focus": {
      border: `1px solid ${colours.borderGrey}`,
      boxShadow: "none",
    },
  },
};

const playingButtonStyles = {
  button: {
    color: colours.primary,
    background: "var(--color-primary-d10)",
    borderColor: "transparent",
    ":hover": {
      background: "var(--color-primary-d10)",
    },
    ":focus": {
      backgroundColor: "var(--color-primary-d10)",
      borderColor: "transparent",
    },
  },
};
const EpisodeListenButton = (props) => {
  const { episode, entity_type, hideTextLabel, passedButtonStyles } = props;

  const { styles, css } = useStyles(baseStyles, props);

  const buttonStyleOverride = passedButtonStyles
    ? mergeDeep(customButtonStyles, passedButtonStyles)
    : customButtonStyles;

  const value = episode?.get("length");
  const text =
    value &&
    secondsToHoursMinSecs(value, (values) => {
      let time = "";

      if (values.hours > 0) {
        time = `${values.hours}h `;
      }
      if (values.minutes > 0) {
        time = `${time}${values.minutes}m `;
      }
      if (values.seconds > 0 && values.hours < 1) {
        time = `${time}${values.seconds}s `;
      }

      return time;
    });

  const renderButton = useCallback(
    (buttonProps) => {
      const { icon, iconSpin, disabled, onClick, isPlaying } = buttonProps;
      const finalButtonStyles = isPlaying
        ? mergeDeep(buttonStyleOverride, playingButtonStyles)
        : buttonStyleOverride;
      return (
        <StandardButton
          icon={icon}
          iconSpin={iconSpin}
          disabled={disabled}
          onClick={onClick}
          overwriteStyles={finalButtonStyles}
          variation={"transparent"}
          flat
          label={
            <>
              {icon && (
                <span className={css(styles.buttonIcon)}>
                  <FontAwesomeIcon icon={icon} spin={iconSpin} fixedWidth />
                </span>
              )}

              {!hideTextLabel && text !== 0 && text}
            </>
          }
        />
      );
    },
    [css, styles.buttonIcon, text, hideTextLabel, buttonStyleOverride]
  );

  return (
    <PlayButton
      key="play"
      entity_type={entity_type}
      entity_id={episode?.get("id")}
      render={renderButton}
    />
  );
};

EpisodeListenButton.propTypes = {
  episode: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
};

export default memo(EpisodeListenButton);
